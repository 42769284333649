
*, body {
  /*font-size: 22px;*/
}

.NavBar{
  text-align: center;
  ::before: >
}

.active{
  background-color: #61dafb;
}

.logo{
  height: 46px;
  width: 230px;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  text-align: center;
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li a:hover:not(.active) {
  background-color: #111;
}


.settings {
  margin-top: 15px;
}


